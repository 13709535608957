import { useEffect, useState, useRef } from "react";
import "./App.css";
import ApfelGrotezkReg from "./fonts/ApfelGrotezk-Regular.ttf";
// import YouTube from "react-youtube";
import AudioPlayer from "./AudioPlayer";
import reactStringReplace from "react-string-replace";
const baseUrl = "https://zf43xf6wdc.execute-api.eu-north-1.amazonaws.com/prod";

function App() {
  const [item, setItem] = useState(null);
  const [exerciseDescription, setExerciseDescription] = useState(null);
  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [chosenLang, setChosenLang] = useState(0);
  const [muted, setMuted] = useState(true);
  const client = require("contentful").createClient({
    space: "e3izhianbwra",
    accessToken: "LXoB-TfP5Lo6-X_xmBNiNKNv0H8I-RToZvEin_dBMJI",
  });
  const mockItem = {
    availability: {
      available: true,
    },
    degree: "Leg. psykolog",
    description: [
      {
        label: "Svenska",
        lang: "sv",
        value:
          "Svenska Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
      },
      {
        label: "English",
        lang: "en",
        value:
          "Engelska Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
      },
      {
        label: "عربي",
        lang: "ar",
        value:
          "عربي Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
      },
    ],
    expertAreas: ["Kodning", "Arkitektur", "Dota 2"],
    firstname: "Niklas",
    id: "eGGAXonstz9RTauq5",
    lastname: "Liljendahl",
    profileImage: "https://i.imgur.com/plkn9U7.jpg",
  };
  useEffect(() => {
    console.log("locationHref", window.location.href);
    if (window.location.href.includes("?id=")) {
      const firstSplit = window.location.href.split("=");
      console.log("FIRST");
      setId(firstSplit[1]);
    } else {
      const split = window.location.href.replace(/\?.*$/g, "").split("/");
      setId(split[split.length - 1]);
    }
  }, [window.location.href]);

  useEffect(() => {
    console.log("HEJ?", id);
    if (id) {
      if (
        window.location.href.includes("?id=") &&
        !window.location.href.includes("fbc")
      ) {
        getCoach(id);
      } else {
        getSinglePost(id);
      }
    }
  }, [id]);

  const getSinglePost = (id) => {
    client.getEntry(id).then((response) => {
      if (response) {
        console.log("RESPONSE", response);
        setItem(response);
        if (
          response.sys.contentType &&
          response.sys.contentType.sys &&
          response.sys.contentType.sys.id === "exercise"
        ) {
          client
            .getEntry(
              response.fields.exerciseSteps[0].fields.exerciseContents[0].sys.id
            )
            .then((res) => {
              console.log("RES", res);
              setExerciseDescription(res.fields.description);
            });
        }
      }
    });
  };
  const formatText = (text) => {
    if (text && text.includes("*")) {
      return reactStringReplace(text, /\*(.*?)\*/gm, (match, i) => (
        <p
          style={{
            fontWeight: "500",
            fontFamily: "ApfelBold",
            marginBottom: 0,
          }}
        >
          {match}
        </p>
      ));
    } else {
      return text;
    }
  };
  const getCoach = async (id) => {
    const rawResponse = await fetch(`${baseUrl}/get-coach/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    console.log("RAW RESPONSE", rawResponse);
    const response = await rawResponse.json();
    console.log("HEJ?", response);
    setItem({ ...response[0], coach: true });
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".App-header");
    const body = document.querySelector("body");
    const scrollTop = window.scrollY;
    scrollTop >= 50
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
    scrollTop >= 50
      ? body.classList.add("is-sticky")
      : body.classList.remove("is-sticky");
  };
  let videoRef = useRef();

  if (item && item.fields && Object.keys(item.fields).length > 0) {
    // const audioElement = `http:${item.fields.mediaObject.fields.mediaFile.fields.file.url}`;
    console.log("ITEM", item);
    const tracks = [
      {
        title: item.fields.mediaObject && item.fields.mediaObject.fields.title,
        artist:
          item.fields.mediaObject && item.fields.mediaObject.fields.creator,
        audioSrc:
          item.fields.mediaObject && item.fields.mediaObject.fields.mediaUri
            ? `${item.fields.mediaObject.fields.mediaUri}`
            : "",
        image:
          item.fields.mediaObject &&
          item.fields.mediaObject.fields.image &&
          item.fields.mediaObject.fields.image.fields.file.url,
        color: "red",
        durationSeconds:
          item.fields.mediaObject &&
          item.fields.mediaObject.fields.durationSeconds,
      },
    ];
    return (
      <div className="App">
        <header className="App-header">
          <div className="inner">
            <a href="https://plussa.app">
              <img src={require("./images/logo_small.png")} className="logo" />
            </a>
            <button
              onClick={() => {
                const body = document.querySelector("body");
                const root = document.querySelector("#root");
                setShowModal(!showModal);
                body.classList.add("modal-open");
                root.classList.add("modal-open");
              }}
              className="modal-btn"
            >
              Ladda ner
            </button>
          </div>
        </header>

        {item.fields.mediaObject &&
        item.fields.mediaObject.fields.type === "video" &&
        item.fields.mediaObject.fields.mediaUri ? (
          <div className="video-responsive">
            <iframe
              width="853"
              height="480"
              src={item.fields.mediaObject.fields.mediaUri}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        ) : item.fields.mediaObject &&
          item.fields.mediaObject.fields.type === "video" &&
          item.fields.mediaObject.fields.mediaFile.fields.file.url ? (
          <video
            ref={videoRef}
            poster=""
            id="player"
            width="320"
            height="570"
            muted
            autoPlay
            controls
          >
            <source
              src={item.fields.mediaObject.fields.mediaFile.fields.file.url}
              type="video/mp4"
            />
          </video>
        ) : item.fields.mediaObject &&
          item.fields.mediaObject.fields.type === "podcast" ? (
          <AudioPlayer tracks={tracks} />
        ) : // <p>Hej</p>
        item.fields.image ? (
          <img
            src={`${item.fields.image.fields.file.url}?w=480`}
            className="contentImg"
            alt={`${item.fields.title} cover`}
          />
        ) : null}
        <h1>{item.fields.title}</h1>
        {/* <p>{item.fields.description}</p> */}
        {console.log("item", item)}
        {exerciseDescription ? (
          <pre>{formatText(exerciseDescription)}</pre>
        ) : (
          <pre>{formatText(item.fields.content)}</pre>
        )}

        <div className="cta-wrp">
          <h2>
            {" "}
            Vill du ta del av denna{" "}
            {item.fields.type === "article"
              ? "artikel"
              : item.fields.type === "podcast"
              ? "podd"
              : item.fields.type === "video"
              ? "video"
              : item.fields.type === "exercise"
              ? "övning"
              : "bok"}{" "}
            och annat som utvecklar ditt föräldraskap?
          </h2>
          <h3>Ladda ner Plussa-appen!</h3>

          <div className="link-wrp">
            <a
              className="apple"
              href="https://apps.apple.com/se/app/plussa/id6443954520"
            >
              <img
                alt="Ladda ner Plussa från AppStore"
                src={require("./images/appstore.png")}
                className="logo"
              />
            </a>
            <a
              className="google"
              href="https://play.google.com/store/apps/details?id=com.plussa"
            >
              <img
                alt="Ladda ner Plussa från Google Play"
                src={require("./images/google.png")}
                className="logo"
              />
            </a>
          </div>
        </div>
        {showModal && (
          <div
            className="modal"
            onClick={(event) => {
              event.preventDefault();
              const body = document.querySelector("#root");
              const root = document.querySelector("#root");
              if (event.target === event.currentTarget) {
                // 👇 your logic here
                setShowModal(false);
                body.classList.remove("modal-open");
                root.classList.remove("modal-open");
              }
            }}
          >
            <div className="cta-wrp">
              <h2>
                Vill du ta del av denna{" "}
                {item.fields.type === "article"
                  ? "artikel"
                  : item.fields.type === "podcast"
                  ? "podd"
                  : item.fields.type === "video"
                  ? "video"
                  : item.fields.type === "exercise"
                  ? "övning"
                  : "bok"}{" "}
                och annat som utvecklar ditt föräldraskap?
              </h2>
              <h3>Ladda ner Plussa-appen!</h3>

              <div className="link-wrp">
                <a
                  className="apple"
                  href="https://apps.apple.com/se/app/plussa/id6443954520"
                >
                  <img
                    alt="Ladda ner Plussa från AppStore"
                    src={require("./images/appstore.png")}
                    className="logo"
                  />
                </a>
                <a
                  className="google"
                  href="https://play.google.com/store/apps/details?id=com.plussa"
                >
                  <img
                    alt="Ladda ner Plussa från Google Play"
                    src={require("./images/google.png")}
                    className="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else if (item) {
    return (
      <div className="App">
        <header className="App-header">
          <div className="inner">
            <img src={require("./images/logo_small.png")} className="logo" />
            <button
              onClick={() => {
                const body = document.querySelector("body");
                const root = document.querySelector("#root");
                setShowModal(!showModal);
                body.classList.add("modal-open");
                root.classList.add("modal-open");
              }}
              className="modal-btn"
            >
              Ladda ner
            </button>
          </div>
        </header>
        <div className="coach-wrp">
          <div
            className="img-wrp"
            style={{
              height: 200,
              width: 200,
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              borderRadius: 100,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                background: "#62B6A2",
                width: 220,
                height: 220,
                position: "absolute",
                borderRadius: 110,
              }}
            ></div>
            <img
              src={item.profileImage}
              style={{ width: "100%", position: "relative", zIndex: 1 }}
              // style={{ width: , height: "100%" }}
            />
          </div>
          <p style={{ fontWeight: "700", marginBottom: 0, fontSize: 20 }}>
            {item.firstname} {item.lastname}
          </p>
          <p style={{ fontWeight: "500", fontSize: 15, marginTop: 8 }}>
            {item.degree}
          </p>
          <p style={{ fontWeight: "500", fontSize: 15, marginTop: 8 }}>
            {item.description[chosenLang].value}
          </p>
          <div className="lang-btns">
            {item.description.map((btn, i) => {
              return (
                <button
                  style={{
                    background: "none",
                    border: "none",
                    color: chosenLang === i ? "#000" : "#F47D79",
                    fontWeight: chosenLang === i ? "400" : "600",
                  }}
                  onClick={() => {
                    setChosenLang(i);
                  }}
                >
                  {btn.label}
                </button>
              );
            })}
          </div>
          <p style={{ marginTop: 50 }}>Expertområden</p>
          <div className="expert-area-wrp">
            {item.expertAreas.map((area, i) => {
              return <p className="expert-area">{area}</p>;
            })}
          </div>
        </div>
        <div className="cta-wrp">
          <h2>
            {" "}
            Vill du få hjälp av {item.firstname} att utveckla ditt föräldraskap?
          </h2>
          <h3>Ladda ner Plussa-appen!</h3>

          <div className="link-wrp">
            <a
              className="apple"
              href="https://apps.apple.com/se/app/plussa/id6443954520"
            >
              <img
                alt="Ladda ner Plussa från AppStore"
                src={require("./images/appstore.png")}
                className="logo"
              />
            </a>
            <a
              className="google"
              href="https://play.google.com/store/apps/details?id=com.plussa"
            >
              <img
                alt="Ladda ner Plussa från Google Play"
                src={require("./images/google.png")}
                className="logo"
              />
            </a>
          </div>
        </div>
        {showModal && (
          <div
            className="modal"
            onClick={(event) => {
              // event.preventDefault();
              const body = document.querySelector("#root");
              const root = document.querySelector("#root");
              if (event.target === event.currentTarget) {
                // 👇 your logic here
                setShowModal(false);
                body.classList.remove("modal-open");
                root.classList.remove("modal-open");
              }
            }}
          >
            <div className="cta-wrp">
              <h2>
                Vill du få hjälp av {item.firstname} att utveckla ditt
                föräldraskap?
              </h2>
              <h3>
                <span>👇</span> Ladda ner Plussa-appen! <span>👇</span>
              </h3>

              <div className="link-wrp">
                <a
                  className="apple"
                  href="https://apps.apple.com/se/app/plussa/id6443954520"
                >
                  <img
                    alt="Ladda ner Plussa från AppStore"
                    src={require("./images/appstore.png")}
                    className="logo"
                  />
                </a>
                <a
                  className="google"
                  href="https://play.google.com/store/apps/details?id=com.plussa"
                >
                  <img
                    alt="Ladda ner Plussa från Google Play"
                    src={require("./images/google.png")}
                    className="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
