import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import ApfelGrotezkReg from "./fonts/ApfelGrotezk-Regular.ttf";
import AudioControls from "./AudioControls";
import Backdrop from "./Backdrop";
const AudioPlayer = ({ tracks }) => {
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  // Destructure for conciseness
  const { title, artist, color, image, audioSrc, durationSeconds } =
    tracks[trackIndex];
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);
  // Destructure for conciseness
  const { duration } = audioRef.current;

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };
  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      clearInterval(intervalRef.current);
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);

    // if (isReady.current) {
    //   audioRef.current.play();
    //   setIsPlaying(true);
    //   startTimer();
    // } else {
    //   // Set the isReady ref as true for the next pass
    //   isReady.current = true;
    // }
  }, [trackIndex]);

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #ff7e79), color-stop(${currentPercentage}, #fff))
  `;
  // const secondsToHours = () => {
  //   let fullHour = durationSeconds / 3600;
  //   let fullMins = fullHour * 60;
  // }
  function pad(val) {
    return val > 9 ? val : "0" + val;
  }
  return (
    <div className="audio-player">
      <div className="track-info">
        <img
          className="artwork"
          src={`${image}?w=480`}
          alt={`track artwork for ${title} by ${artist}`}
          style={{ maxWidth: 500, width: "100%" }}
        />
        <h2 className="title">{title}</h2>
        <h3 className="artist">
          Skapat av: <span>{artist}</span>
        </h3>
        <div className="duration">
          <p>
            {pad(parseInt(Math.ceil(trackProgress) / 3600, 10))}:
            {pad(parseInt(Math.ceil(trackProgress) / 60, 10) % 60)}:
            {pad(Math.ceil(trackProgress) % 60)}
          </p>
          <p>{new Date(durationSeconds * 1000).toISOString().slice(11, 19)}</p>
        </div>
        <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />
        <AudioControls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
        />
        {/* {!audioSrc.includes("?") ? (
          <div className="format-not-supported">
            <p>
              Denna pod kan inte spelas upp här p.g.a. dess format. Ladda ner
              Plussa för att lyssna 👇
            </p>
          </div>
        ) : (
        )} */}
      </div>
      {/* <Backdrop
        trackIndex={trackIndex}
        activeColor={color}
        isPlaying={isPlaying}
      /> */}
    </div>
  );
};
export default AudioPlayer;
