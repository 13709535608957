import React from "react";
import "./App.css";

export const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
}) => (
  <div className="audio-controls">
    {/* <button
      type="button"
      className="prev"
      aria-label="Previous"
      onClick={onPrevClick}
    >
      Föregående
    </button> */}
    {isPlaying ? (
      <button
        type="button"
        className="pause"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      ></button>
    ) : (
      <button
        type="button"
        className="play"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      ></button>
    )}
    {/* <button
      type="button"
      className="next"
      aria-label="Next"
      onClick={onNextClick}
    >
      Nästa
    </button> */}
  </div>
);

export default AudioControls;
